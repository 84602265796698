function rgb(c, alpha=undefined) {
    if (alpha === undefined) {
        return `rgb(${c.join(',')})`
    } else {
        return `rgb(${c.slice(0, -1).join(',')}, ${alpha})`
    }
}

function colorGradient(fadeFraction, rgbColor1, rgbColor2, rgbColor3) {
    let color1 = colorToRGBA(rgbColor1);
    let color2 = colorToRGBA(rgbColor2);
    let fade = fadeFraction;

    if (rgbColor3) {
        fade = fade * 2;

        if (fade >= 1) {
            fade -= 1;
            color1 = color2;
            color2 = colorToRGBA(rgbColor3);
        }
    }

    let diffRed = color2.red - color1.red;
    let diffGreen = color2.green - color1.green;
    let diffBlue = color2.blue - color1.blue;
    let diffAlpha = color2.alpha - color1.alpha;

    let gradient = {
        red: Math.floor(color1.red + (diffRed * fade)).toFixed(0),
        green: Math.floor(color1.green + (diffGreen * fade)).toFixed(0),
        blue: Math.floor(color1.blue + (diffBlue * fade)).toFixed(0),
        alpha: Math.floor(color1.alpha + (diffAlpha * fade)) / 256,
    };

    return [gradient.red, gradient.green, gradient.blue, gradient.alpha];
}

function colorToRGBA(d) {
    return { red: d[0], green: d[1], blue: d[2], alpha: d[3] }
}

export {rgb, colorGradient}
