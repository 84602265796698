<script>
import VueSlider from "vue-slider-component";
import 'vue-slider-component/theme/antd.css'
import {rgb} from "@/util/color";

export default {
  name: 'GradientSlider',
  components: {
    VueSlider
  },
  props: {
    min: {type: Number, default: 0},
    max: {type: Number, default: 100},
    value: {
      type: Array, default() {
        return [25, 50, 75]
      }
    },
    colors: {
      type: Array,
      default() {
        return [
          [255,0,0,0.1],
          [255,0,0,0.3],
          [255,0,0,0.4],
          [255,0,0,0.5],
          [255,0,0,1.0],
        ];
      }
    }
  },
  data() {
    return {
      gradient_small: true,
      gradient_hovered: false,
      key_hovered: false,
    }
  },
  computed: {
    dot_options() {
      return this.colors.slice(1, -1).map((c) => {
        return {
          style: {
            backgroundColor: rgb(c, 0.5),
            border: `2px solid ${rgb(c, 0.5)}`
          },
          focusStyle: {
            boxShadow: `0 0 0 5px ${rgb(c, 0.2)}`
          },
          tooltip: 'always'
        }
      })
    },
    color_positions() {
      return [this.min].concat(this.value).concat([this.max]).map((e, i) => [this.colors[i], e])
    },
    railStyle() {
      let grad_str = "linear-gradient(90deg"
      for (let i of this.color_positions) {
        grad_str += `, ${rgb(i[0], 0.5)} ${i[1]}%`
      }
      grad_str += ")"
      return {
        "background": grad_str
      }
    },
  },
  methods: {
    rgb,
    gradientMouseLeave() {
      this.gradient_hovered = false
      setTimeout(this.gradientLeaveTimout, 500)
    },
    gradientLeaveTimout(_) {
      if (this.key_hovered || this.gradient_hovered) {
        this.gradient_small = false
        return
      }
      this.gradient_small = true
    }
  }
}
</script>

<template>
<div>
  <transition name="fade">
    <div class="gradient-hov" v-if="!gradient_small" @mouseover="gradient_hovered = true"
         @mouseleave="gradientMouseLeave" v-click-outside="gradientLeaveTimout">
      <div class="slider">
        <vue-slider ref="slider"
                    :value="value"
                    v-on:change="$emit('input', $event)"
                    :process-style="{opacity: 0}"
                    :rail-style="railStyle"
                    :dot-options="dot_options" :tooltip-placement="'bottom'">
        </vue-slider>
      </div>
    </div>
  </transition>

  <transition name="fade">
    <div class="gradient-key" v-show="gradient_small"
         @mouseover="key_hovered = true; gradient_small = false" @mouseleave="key_hovered = false"
         v-click-outside="gradientLeaveTimout">
      <div class="key-item left" :style="{backgroundColor: rgb(colors[1], 0.5)}">
        {{ value[0] }}
      </div>
      <div class="key-item middle" v-for="(v, i) in value.slice(1, -1)" :style="{backgroundColor: rgb(colors[i + 2], 0.5)}">
        {{ v }}
      </div>
      <div class="key-item right" :style="{backgroundColor: rgb(colors[colors.length - 2], 0.5)}">
        {{ value[value.length - 1] }}
      </div>
    </div>
  </transition>
</div>
</template>

<style scoped>

.gradient-hov {
  pointer-events: auto;
  z-index: 2;

  width: 300px;
  height: 50px;

  border-radius: 12px;
  background: white;

  transition: opacity 0.5s;
}

.gradient-hov .slider {
  padding: 16px 12px 16px 12px;
}

.gradient-key {
  pointer-events: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  z-index: 2;


  height: 25px;

  border-radius: 4px;
}

.gradient-key .key-item {
  display: flex;
  flex: 1;
  color: black;
  justify-content: center;
  font-size: 1.2em;
  font-weight: bold;
  padding-bottom: 1.5em;
  /* width: 50px; */
  width: 2.6em;
}

.gradient-key .key-item.left {
  border-top-left-radius: 16%;
  border-bottom-left-radius: 16%;
  color: white;
}

.gradient-key .key-item.middle {
  color: white;
}

.gradient-key .key-item.right {
  border-top-right-radius: 16%;
  border-bottom-right-radius: 16%;
  color: white;
}

@media (max-aspect-ratio: 1/1) {
  .gradient-key .key-item {
    font-size: 1em;
    
    /* width: 25px; */
  }
}

</style>
