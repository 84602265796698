<script>
import View from 'ol/View'
import Map from 'ol/Map'
import TileLayer from 'ol/layer/Tile'
import {XYZ} from "ol/source";
import {getPointResolution, useGeographic} from "ol/proj";
import {loadOrchardMap} from "@/util/api";

import 'ol/ol.css'
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {all} from "ol/loadingstrategy";
import {default as OSMXML} from "ol/format/OSMXML";
import {Fill, Icon, Stroke, Style, Text} from "ol/style";
import {Select} from "ol/interaction";
import {never} from "ol/events/condition";
import {ScaleLine, defaults as controlDefaults} from "ol/control";
import mapApi from '@/util/map-api.json'
import {Overlay} from "ol";

export default {
  name: 'OrchardMap',
  components: {},
  data() {
    return {
      map: null,
      vectorSource: null,
      overviewSource: null,
      basemapLayer: null
    }
  },
  props: {
    companies: {
      type: Array,
      default() { return []}
    },
    company: {
      default: null
    },
    kpin: {
      default: null
    },
    bayColor: {
      type: Function,
      default: (_) => "#ff000077"
    },
    hovered: {
      type: Array,
      default() {
        return []
      }
    },
    selected: {
      type: Array,
      default() {
        return []
      }
    },
    geolocation: {
      type: Object,
      default: null
    },
    autoCenter: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    companies(_) {
      this.overviewSource.refresh()
    },
    kpin(_) {
      this.vectorSource.refresh()
    },
    autoCenter(ov, v) {
      if (!ov && v && this.geolocation && this.geolocation.position) {
        this.map.getView().setCenter(this.geolocation.position)
      }
    },
    geolocation(loc) {
      if (loc) {
        const heading = loc.heading
        if (heading === null || heading === undefined) {
          this.$refs.geolocation_marker.src = '/img/icons/geolocation_marker.png'
          this.$refs.geolocation_marker.style.rotate = undefined
        } else {
          this.$refs.geolocation_marker.style.rotate = `${heading}deg`
          this.$refs.geolocation_marker.src = '/img/icons/geolocation_marker_heading.png'
        }
        if (this.autoCenter) {
          this.map.getView().setCenter(loc.position)
        }
        this.marker.setPosition(loc.position);
        // TODO change image if heading is not available / speed is low
      } else {
        // TODO hide marker
        this.marker.setPosition(undefined);
      }
    }
  },
  methods: {
    refreshColors() {
      return this.vectorSource.changed()
    },
    drawOnCanvas(canvas, include_basemap=false) {
      let map = this.map;
      let component = this
      return new Promise((resolve, reject) => {
        let size = map.getSize();
        let viewResolution = map.getView().getResolution();

        if (!include_basemap) {
          map.removeLayer(this.basemapLayer)
        }
        map.once('rendercomplete', function () {
          try {
            console.debug("map @rendercomplete")
            const context2d = canvas.getContext('2d');
            context2d.fillStyle = "white"
            context2d.fillRect(0,0, canvas.width, canvas.height);
            map.getTargetElement().querySelectorAll('.ol-layer canvas').forEach((mapCanvas) => {
                  if (mapCanvas.width > 0) {
                    const opacity = mapCanvas.parentNode.style.opacity;
                    context2d.globalAlpha = opacity === '' ? 1 : Number(opacity);
                    const transform = mapCanvas.style.transform;
                    // Get the transform parameters from the style's transform matrix
                    const matrix = transform.match(/^matrix\(([^(]*)\)$/)[1].split(',').map(Number);
                    // Apply the transform to the export map context
                    context2d.setTransform(matrix)
                    console.debug("Drawing ...")
                    context2d.drawImage(mapCanvas, 0, 0);
                  }
                }
            );
            console.debug("Finished drawing.")
            context2d.globalAlpha = 1;
            context2d.setTransform(1, 0, 0, 1, 0, 0);
            resolve(canvas)
          } catch (e) {
            reject(e)
          } finally {
            // Reset original map size
            let layers = map.getLayers()
            if (layers.getArray().indexOf(component.basemapLayer) < 0) {
              layers.insertAt(0, component.basemapLayer)
            }
            map.setSize(size);
            map.getView().setResolution(viewResolution);
          }
        });
        // Set export size
        map.setSize([canvas.width, canvas.height]);
        const scaling = Math.min(canvas.width / size[0], canvas.height / size[1]);
        map.getView().setResolution(viewResolution / scaling);
      })
    },
    getFeaturesAtCoordinate(coordinate, viewProj = false) {
      return this.vectorSource.getFeaturesAtCoordinate(coordinate, viewProj)
    },
    loadOrchardData(extent, resolution, projection, success, failure) {
      console.debug("Loading orchard data for ", this.company, this.kpin, extent, resolution, projection)
      this.loadMap(this.vectorSource, this.company, this.kpin, resolution, projection, success, failure, true)
    },
    loadOrchardsOverview(extent, resolution, projection, success, failure) {
      console.debug("Loading overview data for ", this.companies, extent, resolution, projection)
      for (let company of this.companies) {
        this.loadMap(this.overviewSource, company, "overview", resolution, projection, success, failure, false)
      }
    },
    loadMap(source, company, kpin, resolution, projection, success, failure, auto_fit) {
      if (company && kpin) {
        loadOrchardMap(company, kpin).then(value => {
          console.debug("Loaded map", !!value)
          const features = new OSMXML().readFeatures(value);
          console.debug("Parsed features", features.length)
          source.addFeatures(features)
          if (auto_fit) {
            for (const feature of features) {
              if (feature.get('acuris') === "orchard") {
                this.map.getView().fit(feature.getGeometry(), {padding: Array(4).fill(50)});
              }
            }
          }
          success(features)
        }).catch(e => {
          console.error("Failed to load map of", company, kpin, e);
          // source.removeLoadedExtent(source.extent)
          failure();
        })
      } else {
        success([])
      }
    },
    mapFeatureOrchardLocation(feature) {
      let orchardLocation = {}
      for (let prop in feature.values_) {
        if (prop.startsWith('location:')) {
          orchardLocation[prop.slice(9)] = feature.values_[prop]
        }
      }
      return orchardLocation
    },
    selectedObjectStyle(feature, resolution) {
      return this.objectStyleInternal(feature, resolution, true, false)
    },
    objectStyle(feature, resolution) {
      return this.objectStyleInternal(feature, resolution, false, false)
    },
    overviewObjectStyle(feature, resolution) {
      return this.objectStyleInternal(feature, resolution, false, true)
    },
    objectStyleInternal(feature, resolution, selected, overview) {
      const extent = feature.getGeometry().getExtent();

      const pointResolution = getPointResolution('EPSG:3857', resolution, extent);
      let acuris_feature = feature.get('acuris')
      if (acuris_feature) {
        return this.acurisStyle(acuris_feature, feature, pointResolution, selected, overview)
      } else {
        return this.otherStyle(feature, pointResolution, overview)
      }
    },
    acurisStyle(kind, feature, resolution, selected, overview) {
      if (overview && feature.get('kpin') === this.kpin) {
        return null
      }
      switch (kind) {
        case "bay": {
          let orchardLocation = this.mapFeatureOrchardLocation(feature)
          let color = this.bayColor(orchardLocation)
          if ((color || resolution < 0.5) && resolution < 10) {
            return new Style({
              zIndex: selected ? 200 : 100,
              fill: new Fill({
                color: color || 'rgba(127, 127, 127, 0.5)'
              }),
              stroke: selected ? new Stroke({color: 'rgb(255, 0, 0)'}) : resolution < 0.5 ? new Stroke({color: `rgba(255,255,255,${1 - resolution / 0.5})`}) : undefined,
              // text: resolution < 0.5 ? this.createTextStyle(resolution.toFixed(2), resolution * 3) : undefined,
            })
          } else {
            return null
          }
        }
        case "row": {
          return new Style({
            zIndex: 101,
            stroke: resolution < 0.5 ? new Stroke({
              color: 'rgba(255, 255 , 255, 1)',
            }) : undefined,
            fill: new Fill({
              color: 'rgba(255, 255 , 255, 0.01)',
            })
          })
        }
        case "block": {
          return new Style({
            zIndex: 102,
            stroke: new Stroke({
              color: 'rgba(255, 255 , 255, 1)',
              lineDash: overview ? [5/resolution, 5/resolution] : undefined,
              lineDashOffset: 0
            }),
            fill: new Fill({
              color: 'rgba(255, 255 , 255, 0.01)',
            }),
            text: this.createTextStyle(feature.get('name'), resolution / 2, 'point', overview ? 'grey': 'black'),
          })
        }
        case "row-center": {
          let name_int = parseInt(feature.get('name'))
          let res_dvblity = Math.round(20 * (resolution - 0.3))
          let name_dvbl = res_dvblity > 1 && name_int !== 1 ? (name_int % res_dvblity === 0) : true
          let name_visible = name_dvbl && (resolution <= 0.6)
          return new Style({
            zIndex: 105,
            text: name_visible ? this.createTextStyle(feature.get('name'), resolution * 2, 'point') : undefined,
          })
        }
        case "orchard": {
          if (resolution > 6) {
            return new Style({
              zIndex: 104,
              text: this.createTextStyle(feature.get('name'), 1, 'point', overview ? 'white': 'black')
            })
          } else {
            return new Style({
              zIndex: 104,
              fill: new Fill({
                color: 'rgba(1, 1, 1, 0.01)',
              }),
              stroke: new Stroke({
                color: 'rgb(157, 157, 157)',
                width: 3,
                lineDash: overview ? [5 / resolution, 5 / resolution] : undefined
              }),
              text: resolution >= 1.6 ? this.createTextStyle(feature.get('name'), resolution / 3, 'point', overview ? 'grey': 'black') : undefined,
            })
          }
        }
        case "section":
          return null;
        default:
          console.log("Unhandled 'acuris' feature type", kind)
      }
    },
    otherStyle(feature, resolution) {
      if (resolution > 3) {
        // console.debug("Resolution", resolution)
        return null
      }
      let power_feature = feature.get('power')
      if (power_feature !== undefined) {
        if (power_feature === "line") {
          return new Style({
            zIndex: 200,
            stroke: new Stroke({
              color: 'black',
              width: 2,
              lineDash: [25, 10, 5, 10, 25, 10]
            })
          });
        }
      }
      let amenity_feature = feature.get('amenity')
      if (amenity_feature !== undefined) {
        // toilets, wind_machine, parking, shelter (natural, no subtype)
        if (amenity_feature === "shelter") {
          let shelter_type_feature = feature.get('shelter_type')
          if (shelter_type_feature !== undefined) {
            if (shelter_type_feature === "natural") {
              return new Style({
                zIndex: 200,
                stroke: new Stroke({
                  color: '#015e00',
                  width: 2 / resolution
                }),
              });
            }
          }
          return new Style({
            zIndex: 200,
            stroke: new Stroke({
              color: '#ffffff',
              width: 0.85
            }),
          });
        } else if (amenity_feature === "parking") {
          return new Style({
            zIndex: 205,
            image: new Icon({
              src: '/static/img/icons/Parking-16.svg'
            })
          });
        } else if (amenity_feature === "toilets") {
          return new Style({
            zIndex: 205,
            image: new Icon({
              src: '/static/img/icons/Toilets-16.svg'
            })
          });
        }
      }
      let barrier_feature = feature.get('barrier')
      if (barrier_feature !== undefined) {
        if (barrier_feature === "gate") {
          return new Style({
            zIndex: 200,
            fill: new Fill({
              color: 'rgba(1,1,1,0.01)',
            }),
            stroke: new Stroke({
              color: 'red',
              width: 0.5
            }),
          });
        } else {
          return new Style({
            zIndex: 100,
            stroke: new Stroke({
              color: '#b4b4b4',
              width: 2
            }),
          });
        }
      }
      let building_type = feature.get('building')
      if (building_type !== undefined) {
        return new Style({
          zIndex: 100,
          stroke: new Stroke({
            color: '#b4b4b4',
            width: 1
          }),
          fill: new Fill({
            color: 'rgba(145,145,145,0.53)',
          }),
        });
      }
      return null
    },
    createTextStyle(text, resolution, placement = 'point', color = 'black') {
      let align = 'center';
      let baseline = 'middle';
      let size = '10px';
      let height = '1';
      let offsetX = 0;
      let offsetY = 0;
      let weight = 'bold';

      let maxAngle = 0.7853981633974483;
      let overflow = true;
      let rotation = 0.0;
      let font = weight + ' ' + size + '/' + height + ' ' + 'Verdana';
      let fillColor = color;
      // let fillColor = 'black';

      return new Text({
        textAlign: align === '' ? undefined : align,
        textBaseline: baseline,
        font: font,
        text: text,
        fill: new Fill({color: fillColor}),

        offsetX: offsetX,
        offsetY: offsetY,
        placement: placement,
        maxAngle: maxAngle,
        overflow: overflow,
        rotation: rotation,
        scale: [1 / resolution, 1 / resolution]
      });
    },
  },
  mounted() {
    // this is where we create the OpenLayers map
    useGeographic()
    const component = this
    this.vectorSource = new VectorSource({
      attributions: "Orchard: <a href=\"https://acurissystems.com\"> Acuris Systems</a>",
      loader: this.loadOrchardData,
      strategy: all
    })
    this.overviewSource = new VectorSource({
      loader: this.loadOrchardsOverview,
      strategy: all
    })
    this.basemapLayer = new TileLayer({
      source: new XYZ({
        attributions: "Basemap: <a href=\"https://basemaps.linz.govt.nz\">&copy; CC BY 4.0 LINZ</a>",
        url: `https://basemaps.linz.govt.nz/v1/tiles/aerial/EPSG:3857/{z}/{x}/{y}.jpg?api=${mapApi['key']}`
      }) // tiles are served by OpenStreetMap
    })
    this.map = new Map({
      target: this.$refs.map_root,
      layers: [
        this.basemapLayer,
        new VectorLayer({
          source: this.overviewSource,
          style: this.overviewObjectStyle,
          declutter: true,
        }),
        new VectorLayer({
          source: this.vectorSource,
          style: this.objectStyle,
          updateWhileAnimating: false,
          updateWhileInteracting: false,
          renderOrder: null
        })
      ],

      // the map view will initially show NZ
      view: new View({
        zoom: 6,
        center: [171.493133, -40.344153],
      }),
      controls: controlDefaults({
            attributionOptions: {collapsible: true},
            zoomOptions: {delta: 0.5}
          }
      ).extend([
        new ScaleLine({bar: true})
      ])
    })
    this.map.on('pointermove', (event) => {
      let features = this.map.getFeaturesAtPixel(event.pixel);
      this.$emit('update:hovered', features)
    })

    const select = new Select({
      filter(f) {
        return f.get('acuris') === 'bay'
      },
      style: this.selectedObjectStyle,
      toggleCondition: never
    })

    select.on('select', function (event) {
      console.debug("Selected", event.selected)
      component.$emit('update:selected', event.selected)
    })
    this.map.addInteraction(select)

    this.marker = new Overlay({
      positioning: 'center-center',
      element: this.$refs.geolocation_marker,
      stopEvent: false,
      autoPan: true,
    });
    this.map.addOverlay(this.marker);
  },
}
</script>

<template>
  <div>
    <div ref="map_root" style="width: 100%; height: 100vh"/>
    <img src="/img/icons/geolocation_marker.png" alt="Current Location" ref="geolocation_marker" />
    <div class="prompt block-prompt" v-if="!company || !kpin">
      <h4>No Orchard Selected.</h4>
      <span>Choose an orchard and a scan from the menus at the top.</span>
    </div>
  </div>
</template>

<style scoped>
.prompt {
  position: absolute;
  text-align: center;

  background-color: lightgray;
  border-radius: 0.5em;
  padding: 0.5rem 1rem 1rem 1rem;
}

.prompt h4 {
  margin-top: 16px;
}

.prompt.block-prompt {
  top: calc(50% - 4rem);
  /* right: calc(50% - 17rem); */
  right: calc(50% - 15rem);
}


@media (max-aspect-ratio: 1/1) {
/* @media (max-aspect-ratio: 8/5) and (min-aspect-ratio: 1/1) { */
  .prompt {
    position: absolute;
    text-align: center;

    background-color: lightgray;
    border-radius: 0.5em;
    padding: 0.5rem 1rem 1rem 1rem;
  }

  .prompt h4 {
    margin-top: 12px;
  }
  .prompt.block-prompt {
    /* top: calc(50% - 2rem); */
    right: calc(50% - 9rem);
    max-width: 18rem;
  }
}
</style>
